import {LoginSlice} from "../../redux/loginReducer";
import {AppDispatch} from "../../redux/store";
import {siteOrigin, backendUrl} from "../../globalConfig";

export const registerUser = (
    login: string,
    email: string,
    telegramUsername: string,
    firstName: string,
    secondName: string,
    userInfo: string
) => async (dispatch: AppDispatch) => {
    fetch(backendUrl+'/auth/register', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': siteOrigin
        },
        body: JSON.stringify({
            login: login,
            email: email,
            telegram_username: telegramUsername,
            first_name: firstName,
            second_name: secondName,
            user_info: userInfo,
            role_id: 1,
            is_active: true,
            is_superuser: false,
            is_verified: false
        })
    })
        .then(response => response.json())
        .then(json => dispatch(LoginSlice.actions.redux_login(json)))
}

