import {backendUrl} from "../../globalConfig";

export const genCode = (login: string) => {
    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }
    if(isValidEmail(login)){
        fetch(backendUrl+'/auth/mail_auth/gen_code', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: login
            })
        })
    }else{
        fetch(backendUrl+'/auth/telegram_auth/gen_code', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                telegram_username: login.replace("https://t.me/","")
            })
        })
    }

}
