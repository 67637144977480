import {LoginSlice} from "../../redux/loginReducer";
import {AppDispatch} from "../../redux/store";
import {siteOrigin, backendUrl} from "../../globalConfig";

export const loginUserByMailAsync = (v_email: string, v_password: string) => async (dispatch: AppDispatch) => {
    fetch(backendUrl+'/auth/mail_auth/login', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Access-Control-Allow-Origin': siteOrigin
        },
        body: new URLSearchParams({
            'username' : v_email,
            'password': v_password,
        })
    }).then(() => {return fetch(backendUrl+'/auth/me', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then(response => response.json())
        .then(json => dispatch(LoginSlice.actions.redux_login(json)))
    })
}