import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {Paper} from "@mui/material";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
const NotFoundPage: React.FC = () => {
    return(
        <>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '85vh',
                    justifyContent: 'center',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}
            >
                <Paper />
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="h1" gutterBottom>
                        Page not found
                    </Typography>
                </Stack>
            </Box>
        </>
    )
}

export default NotFoundPage;